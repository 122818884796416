<template>
  <div class="col-12 mb-3">
    <div class="row mx-0 align-items-center">
      <div class="col-auto red-text bold font12">
        A donor profile is registered under this email
      </div>
      <div class="col p-0">
        <Button color="gold" btnText="Login Now" size="xsmall" icon="arrow" class="w-100" @buttonClicked="isLogin = true">
          <IconArrowForward color="black" size="size16" />
        </Button>
      </div>
    </div>
    <transition name="fade">
      <LoginPopup v-if="isLogin" @close="isLogin = false" @loggedIn="isLogin = false"/>
    </transition>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue'))
  },
  name: 'Daily Sadaqah Login Section',
  data () {
    return {
      isLogin: false
    }
  }
}

</script>
